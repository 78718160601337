import { Card, CardBody, CardHeader, Grid, Heading, Text } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

export interface CardGridItem {
  heading: string;
  body: string;
}
export interface CardGridProps {
  items: CardGridItem[];
  headingSize?: string;
}

export const cardGridMaxW = 'md';

export function CardGrid({ items, headingSize, children }: PropsWithChildren<CardGridProps>) {
  return (
    <Grid templateColumns="repeat(auto-fit, minmax(320px, 1fr))" gap={4} minWidth="50%">
      {items.map((item: CardGridItem) => (
        <Card key={item.heading} maxW={cardGridMaxW}>
          <CardHeader>
            <Heading size={headingSize || 'md'}>{item.heading}</Heading>
          </CardHeader>
          <CardBody>
            <Text>{item.body}</Text>
          </CardBody>
        </Card>
      ))}
      {children}
    </Grid>
  );
}
