import { Card, CardBody, CardHeader, Heading, ListItem, UnorderedList } from '@chakra-ui/react';
import React from 'react';

export interface ListCardProps {
  heading: string;
  items: string[];
}

export function ListCard({ heading, items }: ListCardProps) {
  return (
    <Card>
      <CardHeader>
        <Heading size="md">{heading}</Heading>
      </CardHeader>
      <CardBody>
        <UnorderedList>
          {items.map((item: string) => (
            <ListItem key={item}>{item}</ListItem>
          ))}
        </UnorderedList>
      </CardBody>
    </Card>
  );
}
