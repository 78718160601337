import { Center, Grid, Text, VStack } from '@chakra-ui/react';
import { graphql } from 'gatsby';
import React from 'react';
import { CardGrid } from '../components/CardGrid';
import { ListCard } from '../components/ListCard';
import { Page } from '../components/Page';
import { PageHeader } from '../components/PageHeader';
import { seo } from '../components/Seo';

interface AboutItem {
  heading: string;
  body: string;
}
interface Props {
  data: {
    aboutJson: {
      intro: string;
      items: AboutItem[];
      weAre: string[];
      weAreNot: string[];
    };
  };
}

export const Head = seo({ title: 'About' });

export default function AboutPage({
  data: {
    aboutJson: { intro, items, weAre, weAreNot },
  },
}: Props) {
  return (
    <Page>
      <VStack>
        <PageHeader title="About" />
        <Center padding={4}>
          <Text>{intro}</Text>
        </Center>
      </VStack>

      <Grid templateColumns="repeat(auto-fit, minmax(320px, 1fr))" gap={4}>
        <ListCard heading="We Are" items={weAre} />
        <ListCard heading="We Are Not" items={weAreNot} />
      </Grid>

      <CardGrid items={items} />
    </Page>
  );
}

export const pageQuery = graphql`
  query AboutPageTemplate {
    aboutJson {
      intro
      items {
        heading
        body
      }
      weAre
      weAreNot
    }
  }
`;
